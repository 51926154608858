@media (min-width: 1025px) {
    .h-custom {
    height: 100vh !important;
    }
    }

    .horizontal-timeline .items {
    border-top: 2px solid #ddd;
    }

    .horizontal-timeline .items .items-list {
    position: relative;
    margin-right: 0;
    }

    .horizontal-timeline .items .items-list:before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ddd;
    top: 0;
    margin-top: -5px;
    }

    .horizontal-timeline .items .items-list {
    padding-top: 15px;
    }