/* HTML: <div class="loader"></div> */
.LoaderContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* HTML: <div class="loader"></div> */
.loader {
   width: 150px;
   aspect-ratio: 1;
   display: grid;
   border: 4px solid #0000;
   border-radius: 50%;
   border-right-color: blue;
   animation: l15 1s infinite linear;
 }
 .loader::before,
 .loader::after {
   content: "";
   grid-area: 1/1;
   margin: 2px;
   border: inherit;
   border-radius: 50%;
   animation: l15 2s infinite;
 }
 .loader::after {
   margin: 8px;
   animation-duration: 3s;
 }
 @keyframes l15{
   100%{transform: rotate(1turn)}
 }