.NewArivalProducts{
    
    display: grid;
    width: auto;
    height:auto;
    grid-template-columns:repeat(4,1fr);
    gap: 0.5rem;
    margin-top: 5rem;    
}



@media screen and (max-width:980px) {
  .NewArivalProducts{
    grid-template-columns: 1fr;
}

  
}
  

