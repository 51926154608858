/* General Styles */
.UpperNav span {
    display: flex;
    background-color: #252725;
    padding: 0.3rem;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.UpperNav span:hover {
    background-color: #404240;
}

.icons {
    font-size: 22px;
    margin-right: 0.3rem;
}



/* Sidebar Styles */
.MobNavContainer{
    display: none;
}

.mobileLogo{
    display: none;
}

.sideNav {
    position: fixed;
    background-color: white;
    transition: transform 0.5s ease;
    width: 22%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: translateX(-100%);
    z-index: 1000;
}

.sideNav.open {
    transform: translateX(0);
}

.sideNav ul {
    list-style: none;
    padding: 2rem;
    margin: 0;
}

.sideNav ul li {
    margin-bottom: 1rem;
    padding: 0.2rem;
    border-bottom: 1px solid rgb(204, 200, 200);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sideNav ul li:hover {
    background-color: #f0f0f0;
}

/* Menu Styles */
.Menue {
    font-family: "Roboto Slab", serif;
    font-weight: 600;
    background-color: #063885;
    border-radius: 10px;
}

.DesktopNavBar li a {
    text-decoration: none;
    color: white;
    transition: all 0.5s ease;
}

.DesktopNavBar li a:hover {
    background-color: white;
    color: #252725;
    transform: scale(1.05);
    padding: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-weight: bold;
}

/* Navbar Icon Styles */
.leftNav {
    width: 10%;
}



/* Responsive Styles */
@media screen and (max-width: 980px) {

    .searchInput{
        width: 9rem;
        >input{
            width: 9rem;
        }
    }

    .Menue{
        display: none;
    }

    .MobNavContainer {
        display: block;
        width: 10%;
        left: 0;
    }

    .MobileNav {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        position: absolute;
        font-size: 25px;
        padding: 0.1rem;
    }

    .mobileLogo {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 15px;
    }

    .sideNav {
        width: 70%;
        top: 0;
    }

   .navIcon {
        font-size: 2em;
        color: black;
        cursor: pointer;
        transition: transform 0.3s ease;
    }

    .navIcon:hover {
        transform: scale(1.1);
    }


}

@media screen and (max-width: 1369px) {
    .DesktopNavBar li {
        font-size: 12px;
    }
}

@media screen and (max-width: 1140px) {
    .DesktopNavBar li {
        font-size: 10px;
    }
}


