.BillingForm{
    width: 100%;
    height: 100vh;
    display: flex;
    gap: 0.1rem;
}

.LeftSizeForm{
    width: 70%;
    background: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: 1rem;
    overflow: auto;
}

.LeftSizeForm form{
    width: 100%;
    margin: 1.5rem;

    input, select, textarea{
        border: 2px solid rgb(211, 211, 211);
        margin: 0.5rem;
    }
}

.RightSizeSummary{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    border-radius: 10px;
    background: white;
    max-height: 30vh;
    margin: 1rem;
}

.orderDetail{
    display: grid;
    grid-template-columns: repeat(2,1fr );
    gap: 0.5rem;
    margin: auto;
}

@media screen and (max-width:980px) {
    .BillingForm{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .LeftSizeForm,
    .RightSizeSummary{
        width: 90%;
    }

    .RightSizeSummary{
        height: 50vh;
    }


}