.ProductTableForMobile,
.CartDataContainer
{
    display: none;
}


@media screen and (max-width:980px) {

    .ProductsTable{
        display: none;
    }

    .AmountContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
        font-size: 12px;
    }

    .ProductTableForMobile{
        display: grid;
        gap: 0.5rem;
        width: 100%;
        background: white;
        border: 2px solid gray;
        border-radius: 10px;
        padding: 1rem;

    }

    .CartDataContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}