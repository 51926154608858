@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=New+Amsterdam&family=Roboto+Slab:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}


.Heading::before,
.Heading::after{
    content: "";
    flex: 1;
    border-bottom: 2px solid #666565;
    margin: 0 10px;
}



