
.card-stepper {
    z-index: 0
    }
    
    #progressbar-2 {
    color: #455A64;
    }
    
    #progressbar-2 li {
    list-style-type: none;
    font-size: 13px;
    width: 33.33%;
    float: left;
    position: relative;
    }
    
    #progressbar-2 #step1:before {
    content: "Done";
    font-family: "Font Awesome 5 Free";
    color: #fff;
    width: 40px;
    margin-left: 0px;
    padding-left: 0px;
    }
    
    #progressbar-2 #step2:before {
    content: '\f058';
    font-family: "Font Awesome 5 Free";
    color: #fff;
    width: 37px;
    }
    
    #progressbar-2 #step3:before {
    content: '\f058';
    font-family: "Font Awesome 5 Free";
    color: #fff;
    width: 37px;
    margin-right: 0;
    text-align: center;
    }
    
    #progressbar-2 #step4:before {
    content: '\f111';
    font-family: "Font Awesome 5 Free";
    color: #fff;
    width: 37px;
    margin-right: 0;
    text-align: center;
    }
    
    #progressbar-2 li:before {
    line-height: 37px;
    display: block;
    font-size: 12px;
    background: #c5cae9;
    border-radius: 50%;
    }
    
    #progressbar-2 li:after {
    content: '';
    width: 100%;
    height: 10px;
    background: #c5cae9;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 15px;
    z-index: -1;
    }
    
    #progressbar-2 li:nth-child(1):after {
    left: 1%;
    width: 100%
    }
    
    #progressbar-2 li:nth-child(2):after {
    left: 1%;
    width: 100%;
    }
    
    #progressbar-2 li:nth-child(3):after {
    left: 1%;
    width: 100%;
    background: #c5cae9 !important;
    }
    
    #progressbar-2 li:nth-child(4) {
    left: 0;
    width: 37px;
    }
    
    #progressbar-2 li:nth-child(4):after {
    left: 0;
    width: 0;
    }
    
    #progressbar-2 li.active:before,
    #progressbar-2 li.active:after {
    background: #2643e7;
    }

        
