.OrderTracker{
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.TrackerForm{
    width: 100%;
    height: 35vh;
    background: white;
    border: 2px solid gray;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: 1rem;
    overflow: auto;
}

.TrackerForm form{
    width: 100%;
    margin: 1.5rem;

    input, select, textarea{
        border: 2px solid rgb(211, 211, 211);
        margin: 0.5rem;
    }
}

.Tracker{
    height: 100%;
}


