.productImg{
    margin: auto;
    height: 80vh;
    width: 70%;
    border-radius: 15px;
}

@media screen and (max-width:980px) {
    .productImg{
        margin: auto;
        height: 100%;
        width: 95%;
        border-radius: 15px;
    }
}