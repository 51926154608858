.product-card {
    opacity: 0; /* Initially hidden */
    transform: translateX(-100%); /* Start from the left */
    transition: transform 1s ease-out, opacity 1s ease-out;
  }

  .product-card.animate {
    opacity: 1; /* Fully visible */
    transform: translateX(0); /* Move to the center */
  }

  .NewArivalProducts{

    display: grid;
    width: auto;
    height:auto;
    grid-template-columns:repeat(4,1fr);
    gap: 0.5rem;
    margin: 5rem 0 5rem 1rem;
}

.productFilter{
  display: none;
}

.ProductList{
  width: 80%;
  min-height: 100%;
  overflow: auto;
  border-left: 2px solid gray;
}
@media screen and (max-width:980px) {

  .productFilter{
    width: 100%;
    margin: auto;
  }
  .NewArivalProducts{
    grid-template-columns: 1fr;
}
.ProductList{
  width:100%;
  border: none;
}
.productFilterleft{
  display: none;
}

}


